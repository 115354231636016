import 'es6-promise/auto';

require('array.prototype.find').shim();
require('smoothscroll-polyfill').polyfill();

//import bindArrowToTop from './components/arrow-to-top';
//import bindScrollLinks from './components/scroll-links';

import initCookieNotice from "./vbcn";

function initGlobal() {

    //bindArrowToTop();
    //bindScrollLinks();

    initCookieNotice();

}

document.addEventListener('DOMContentLoaded', function() {
    initGlobal();
})


