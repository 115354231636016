import {addClass, toggleClass, lang, hasClass} from "./helpers";
import {cookieNoticeTexts, cookieSets, cookieNoticeSettings} from "./config";
import {cookieState} from "./index";

require('array.prototype.find').shim();

const cnOptions = {
    texts: null,
    options: null
};

const cnState = {
    isInit: false,
};

export const cnEls = {
    cnNotice: null,
    cnBackdrop: null,
    cnInitialContent: null,
    cnCustomizeContent: null,
    cnOptionsNode: null,
    cnOptions: [],
    buttons: {
        accept_preset: null,
        decline: null,
        customize: null,
        cancel_customize: null,
        decline_all: null,
        save: null,
    }
};

export function initCookieNotice() {

    cnOptions.texts = cookieNoticeTexts[lang];
    cnOptions.options = cookieSets[lang];

    let cookieNotice = buildCookieNotice();
    let backdrop = buildBackdrop();

    cnState.isInit = true;

    const isOnIgnoredPage = (cookieNoticeSettings.ignoredPages && cookieNoticeSettings.ignoredPages.length) ? cookieNoticeSettings.ignoredPages.indexOf(cookieState.currentPath) >= 0 : false;

    if(cookieState.cookiesAllowed || isOnIgnoredPage) {

        let highestZIndex = findZIndex();

        if(cookieNoticeSettings.useBackdrop) {
            backdrop.style.zIndex = highestZIndex + 1;
            cookieNotice.style.zIndex = highestZIndex + 2;

            document.querySelector('body').append(backdrop);
        }

        document.querySelector('body').append(cookieNotice);
    } else {

        let highestZIndex = findZIndex();

        if(cookieNoticeSettings.useBackdrop) {
            backdrop.style.zIndex = highestZIndex + 1;
            cookieNotice.style.zIndex = highestZIndex + 2;

            document.querySelector('body').append(backdrop);
            showBackdrop();
        }

        document.querySelector('body').append(cookieNotice);
        showCookieNotice();
    }
}

function findZIndex() {
    let highestZIndex = 0;
    Array.from(document.querySelector('body').children).forEach(sibling => {
        let zIndex = parseInt(window.getComputedStyle(sibling).zIndex);
        if(!!zIndex) {
            highestZIndex = highestZIndex >= zIndex ? highestZIndex : zIndex;
        }
    });
    return highestZIndex;
}

export function hideCookieNotice() {
    cnEls.cnNotice.style.display = 'none';
}

export function showCookieNotice() {
    cnEls.cnNotice.style.display = 'block';
}

export function hideBackdrop() {
    cnEls.cnBackdrop.style.display = 'none';
}

export function showBackdrop() {
    cnEls.cnBackdrop.style.display = 'block';
}

export function removeBackdrop() {
    cnEls.cnBackdrop.parentNode.removeChild(cnEls.cnBackdrop);
}

export function removeCookieNotice() {
    cnEls.cnNotice.parentNode.removeChild(cnEls.cnNotice);
}

export function toggleCustomizeMode() {
    toggleClass(cnEls.cnNotice, 'is-config-mode')
}

export function showCustomizeMode() {
    addClass(cnEls.cnNotice, 'is-config-mode')
}

export function buildCookieNotice() {

    cnEls.cnNotice = document.createElement('div');
    addClass(cnEls.cnNotice, 'vbcn');
    if(cookieNoticeSettings.centerMode) {
        addClass(cnEls.cnNotice, 'is-centered');
    }
    cnEls.cnNotice.id = 'vbcn';
    cnEls.cnNotice.style.display = 'none';

    let cookieNoticeContent = document.createElement('div');
    addClass(cookieNoticeContent, 'vbcn-content');

    cnEls.cnInitialContent = document.createElement('div');
    addClass(cnEls.cnInitialContent, 'vbcn-preset-view');
    cnEls.cnInitialContent.innerHTML = `<h5 class="vbcn-title">${ cnOptions.texts.initialHeadline }</h5><p class="vbcn-text">${ cnOptions.texts.explanationText }</p>`;

    cnEls.cnCustomizeContent = document.createElement('div');
    addClass(cnEls.cnCustomizeContent, 'vbcn-details-view');
    cnEls.cnCustomizeContent.innerHTML = `<h5 class="vbcn-title">${ cnOptions.texts.customizeHeadline }</h5>`;

    cnEls.cnOptionsNode = document.createElement('div');
    addClass(cnEls.cnOptionsNode, 'vbcn-options');

    cnOptions.options.forEach(option => {
        let optionNode = buildOption(option);
        cnEls.cnOptions.push(optionNode);
        cnEls.cnOptionsNode.append(optionNode);
    });

    cnEls.cnCustomizeContent.append(cnEls.cnOptionsNode);

    // buttons
    let cookieNoticeButtons = buildButtons();

    cnEls.cnNotice.append(cnEls.cnInitialContent, cnEls.cnCustomizeContent, cookieNoticeButtons );

    if(cookieNoticeSettings.backgroundColor) {
        cnEls.cnNotice.style.backgroundColor = cookieNoticeSettings.backgroundColor;
    }

    if(cookieNoticeSettings.textColor) {
        cnEls.cnNotice.style.color = cookieNoticeSettings.textColor;
    }

    return cnEls.cnNotice;
}

function buildOption(option) {
    let optionNode = document.createElement('label');
    addClass(optionNode, 'vbcn-option');

    optionNode.innerHTML = `${ option.name }<input type="checkbox" name="vbcn-option-${ option.id }" id="vbcn-option-${ option.id }" class="vbcn-option-input" data-option="${ option.id }">
<span class="vbcn-option-checkmark"></span>
<span class="vbcn-option-desc">${ option.description }</span>
    </label>`;

    if(option.mandatory) {
        addClass(optionNode, 'vbcn-mandatory-option');

        let textnode = document.createElement('span');
        addClass(textnode, 'vbcn-option-mandatory-text');
        textnode.style.display = "none";
        textnode.innerHTML = option.mandatory_text;

        optionNode.append(textnode);
        // let input = optionNode.querySelector('.vbcn-option-input');
        // input.setAttribute("checked", "checked");
        // input.setAttribute("disabled", "disabled");
    }

    if(cookieState.allowedData && cookieState.allowedData.options) {
        if (cookieState.allowedData.options.hasOwnProperty(option.id)) {
            if (cookieState.allowedData.options[option.id] === true) {
                let input = optionNode.querySelector('.vbcn-option-input');
                input.checked = true;
            }
        }
    }

    let input = optionNode.querySelector('.vbcn-option-input');
    input.addEventListener('change', event => {
        if(!hasClass(event.currentTarget.parentNode, 'vbcn-mandatory-option')) {
            if(cookieNoticeSettings.autoEnableMandatory) {
                setMandatoryOptionsToChecked();
            }
        }
        console.log(event.currentTarget);
        console.log(event.currentTarget === input);
    });

    return optionNode;
}

function setMandatoryOptionsToChecked() {
    let cnOptions = cnEls.cnOptions.filter(optNode => hasClass(optNode, 'vbcn-mandatory-option'));
    let optionsArray = Array.from(cnOptions);

    optionsArray.forEach(cnOption => {
        let input = cnOption.querySelector('.vbcn-option-input');
        console.log(input);
        input.checked = true;
    });

    console.log(optionsArray[0]);
    optionsArray[0].scrollIntoView({ behavior: 'smooth' });
}

export function highlightMandatory() {
    let cnOptions = cnEls.cnOptions.filter(optNode => hasClass(optNode, 'vbcn-mandatory-option'));
    let optionsArray = Array.from(cnOptions);
    optionsArray.forEach(cnOption => {
        let text = cnOption.querySelector('.vbcn-option-mandatory-text');
        text.style.display = "block";
    });

    console.log(optionsArray[0]);
    optionsArray[0].scrollIntoView({ behavior: 'smooth' });
}

export function unhighlightMandatory() {
    let cnOptions = cnEls.cnOptions.filter(optNode => hasClass(optNode, 'vbcn-mandatory-option'));
    Array.from(cnOptions).forEach(cnOption => {
        let text = cnOption.querySelector('.vbcn-option-mandatory-text');
        text.style.display = "none";
    });
}


export function checkIfMandatoryOptionsSet() {
    let result = true;
    let cnOptions = cnEls.cnOptions.filter(optNode => hasClass(optNode, 'vbcn-mandatory-option'));
    Array.from(cnOptions).forEach(cnOption => {
        let input = cnOption.querySelector('.vbcn-option-input');
        if (!input.checked) {
            result = false;
        }
    });

    return result;
}

function buildButtons() {
    let cookieNoticeButtons = document.createElement('div');
    addClass(cookieNoticeButtons, 'vbcn-buttons');

    cnEls.buttons.accept_preset = document.createElement('button');
    addClass(cnEls.buttons.accept_preset, 'vbcn-button');
    addClass(cnEls.buttons.accept_preset, 'primary');
    cnEls.buttons.accept_preset.id = 'vbcn-accept-preset';
    cnEls.buttons.accept_preset.innerText = cnOptions.texts.accept_preset_ButtonText;

    if(cookieNoticeSettings.hasDeclineButton) {
        cnEls.buttons.decline = document.createElement('button');
        addClass(cnEls.buttons.decline, 'vbcn-button');
        cnEls.buttons.decline.id = 'vbcn-decline';
        cnEls.buttons.decline.innerText = cnOptions.texts.declineButtonText;
    }

    cnEls.buttons.customize = document.createElement('button');
    addClass(cnEls.buttons.customize, 'vbcn-button');
    cnEls.buttons.customize.id = 'vbcn-customize';
    cnEls.buttons.customize.innerText = cnOptions.texts.customizeButtonText;

    cnEls.buttons.cancel_customize = document.createElement('button');
    addClass(cnEls.buttons.cancel_customize, 'vbcn-button');
    cnEls.buttons.cancel_customize.id = 'vbcn-cancel-customize';
    cnEls.buttons.cancel_customize.innerText = cnOptions.texts.cancel_customizeButtonText;

    cnEls.buttons.save = document.createElement('button');
    addClass(cnEls.buttons.save, 'vbcn-button');
    addClass(cnEls.buttons.save, 'primary');
    cnEls.buttons.save.id = 'vbcn-save';
    cnEls.buttons.save.innerText = cnOptions.texts.saveButtonText;


    if(cookieNoticeSettings.hasDeclineButton) {
        cookieNoticeButtons.append(cnEls.buttons.decline);
    }
    cookieNoticeButtons.append(cnEls.buttons.customize);
    cookieNoticeButtons.append(cnEls.buttons.cancel_customize);
    cookieNoticeButtons.append(cnEls.buttons.save);
    cookieNoticeButtons.append(cnEls.buttons.accept_preset);

    return cookieNoticeButtons
}

export function updateOptions(options) {
    if (cnState.isInit) {
        for (let option in options) {
            if(options.hasOwnProperty(option)) {
                let cnOption = cnEls.cnOptions.find(optNode => optNode.querySelector('.vbcn-option-input').dataset.option === option);
                let cnOptionProto = cnOptions.options.find(protoOpt => protoOpt.id === option);
                let input = cnOption.querySelector('.vbcn-option-input');

                if(options[option]) {
                    input.checked = true
                } else {
                    input.checked = false
                }
            }
        }
    }
}

export function buildBackdrop() {
    cnEls.cnBackdrop = document.createElement('div');
    addClass(cnEls.cnBackdrop, 'vbcn-backdrop');
    cnEls.cnBackdrop.id = 'vbcn-backdrop';
    cnEls.cnBackdrop.style.display = 'none';
    cnEls.cnBackdrop.style.pointerEvents = 'all';

    if(cookieNoticeSettings.backdropColor) {
        cnEls.cnBackdrop.style.backgroundColor = cookieNoticeSettings.backdropColor;
    }

    if(cookieNoticeSettings.backdropOpacity) {
        cnEls.cnBackdrop.style.opacity = cookieNoticeSettings.backdropOpacity;
    }

    return cnEls.cnBackdrop;
}
