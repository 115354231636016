import {
    cnEls,
    initCookieNotice,
    toggleCustomizeMode,
    showCustomizeMode,
    hideCookieNotice,
    showCookieNotice,
    hideBackdrop,
    showBackdrop,
    highlightMandatory, unhighlightMandatory
} from "./cookienotice";
import {
    collectVBCNCookieData,
    enableSpecificType,
    getVBCNCookieData,
    renewVBCNCookie,
    setVBCNCookie,
    unsetVBCNCookie, updateCookieData
} from "./vbcn";
import {cookieNoticeSettings, cookieSets} from "./config";
import {lang, findAncestorByClass} from "./helpers";
import {scriptsStack, collectScripts, executeScripts} from "./scripthandler";
import { appendPolyfill } from './polyfills';

export const cookieState = {
    cookieDomain: null,
    cookiesAllowed: false,
    allowedData: null,
    currentPath: null
};

export default function init () {

    appendPolyfill();

    cookieState.cookieDomain = document.location.host;
    cookieState.currentPath = document.location.pathname;

    if (getVBCNCookieData()) {
        cookieState.allowedData = getVBCNCookieData();
        cookieState.cookiesAllowed = cookieState.allowedData.cookiesAllowed;

        renewVBCNCookie();
    } else {

    };

    collectScripts();
    initCookieNotice();
    bindCookieNoticeButtons();

    let placeholders  = document.querySelectorAll('.js-vbcn-execute');

    Array.from(placeholders).forEach(placeholder => {
        placeholder.addEventListener('click', function(e) {
            let scriptType = e.currentTarget.dataset.type.toLowerCase();
            enableSpecificType(scriptType);
            removePlaceHoldersForType(scriptType);
            executeScripts(scriptType);
            hideBackdrop();
            hideCookieNotice();
        })
    });

    let customizeLinks = document.querySelectorAll('.js-vbcn-customize');

    Array.from(customizeLinks).forEach(customizeLink => {
        customizeLink.addEventListener('click', function(e) {
            showBackdrop();
            showCookieNotice();
            showCustomizeMode();
        })
    });

    if (cookieState.cookiesAllowed && cookieState.allowedData) {
        window.cookieState = cookieState;
        enableAllowedScripts(cookieState.allowedData.options);
    } else {
        window.cookieState = null;
        console.log("no cookies set");
    }
}

function enableAllowedScripts(options) {
    console.log(cookieState.allowedData.options);
    console.log(scriptsStack);

    for (let option in options) {
        if (options.hasOwnProperty(option)) {

            if(options[option] === true) {
                removePlaceHoldersForType(option);
                executeScripts(option);
            }
        }
    }
}

function removePlaceHoldersForType(type) {
    let placeholders  = document.querySelectorAll('.js-vbcn-execute');
    let typePlaceholders = Array.from(placeholders).filter(placeholder => placeholder.dataset.type === type);

    typePlaceholders.forEach(placeholder => {
        let parent = findAncestorByClass(placeholder, 'js-vbcn-placeholder');
        parent.parentNode.removeChild(parent);
    })
}

function bindCookieNoticeButtons() {

    cnEls.buttons.customize.addEventListener('click', function() {
        toggleCustomizeMode();

    });

    cnEls.buttons.cancel_customize.addEventListener('click', function() {
        toggleCustomizeMode();

    });


    cnEls.buttons.accept_preset.addEventListener('click', function() {
        let cookieValue = {
            cookiesAllowed: true,
            options: {}
        };

        cookieSets[lang].forEach(cookieSet => {
            cookieValue.options[cookieSet.id] = true;
        });

        setVBCNCookie(cookieValue);
        hideBackdrop();
        hideCookieNotice();
        unhighlightMandatory();
        enableAllowedScripts(cookieValue.options);
    });

    cnEls.buttons.save.addEventListener('click', function() {
        let cookieValue = collectVBCNCookieData();
        // debugger;
        if(!cookieValue.cookiesAllowed) {
            highlightMandatory();
            return;
        }

        console.log(cookieValue);
        setVBCNCookie(cookieValue);
        hideBackdrop();
        hideCookieNotice();
        unhighlightMandatory();
        enableAllowedScripts(cookieValue.options)
        // window.location.reload();
    });

    if(cookieNoticeSettings.hasDeclineButton) {
        cnEls.buttons.decline.addEventListener('click', function() {
            console.log("decline");
            unsetVBCNCookie();
            hideCookieNotice();
            unhighlightMandatory();
            // window.location.reload();
        });
    }

}
